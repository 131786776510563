.imgWrap :global(.ant-image-mask) {
  background: rgba(0, 0, 0, 0.2) !important;
}

.imgWrap :global(.ant-image .ant-image-img) {
  transition: transform 0.25s ease-in-out;
}

.imgWrap:hover :global(.ant-image .ant-image-img) {
  transform: scale(1.15);
}
