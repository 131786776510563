.container {
  position: relative;
  cursor: none;
  user-select: none;
}

.imageWrapper {
  position: relative;
}

.magnifyingGlass {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.magnifyingArea {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  transform: translate(-50%, -50%);
  overflow: hidden;
  box-sizing: border-box;
}
