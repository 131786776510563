.contactInfo {
  transition: background-color 0.3s;
}

.contactInfoDetail {
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contactInfo:hover .contactInfoDetail {
  opacity: 1;
  pointer-events: auto;
}
