@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-sunshine: #f78c26;
  --color-sunshine-light: #f6b332;
  --color-sunshine-dark: #e16a2c;
  --color-red: #ad172b;
  --color-grey: #d3d3d3;
  --color-grey-dark: #a1a1a1;
}

body {
  margin: 0;
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:focus,
input:focus,
p:focus,
div:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ant-image-preview-root .ant-image-preview-mask {
  z-index: 10000000;
}
.ant-image-preview-root .ant-image-preview-wrap {
  z-index: 10000001;
}
.ant-image-preview-operations-wrapper {
  z-index: 10000002;
}

.ant-message {
  z-index: 10000003;
}

.img-scale:hover {
  transform: scale(1.15);
  transition: transform 0.3s ease-in-out;
}

.img-scale + .ant-image-mask {
  background: transparent !important;
}
