@media (min-width: 769px) {
  .menu {
    transform: translateY(16px) translateX(0);
  }

  .otherMenu {
    transform: translateY(0) translateX(16px);
  }

  .hoverMenu {
    transform: translateY(4px) translateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  .hoverOtherMenu {
    transform: translateY(0) translateX(4px);
    opacity: 1;
    pointer-events: auto;
  }
}

:global a:hover {
  color: #fff;
}
