:global(.mySwiper) {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

:global(.mySwiper .swiper-wrapper) {
  display: flex;
  align-items: center;
}

:global(.mySwiper .swiper-slide) {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

:global(.mySwiper .swiper-slide img) {
  display: block;
  /*width: 100%;*/
  height: 100%;
  object-fit: contain;
}

@screen md {
  :global(.mySwiper .swiper-slide) {
    width: 600px;
    height: 600px;
  }
}
